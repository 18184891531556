//@import '~bootstrap/scss/bootstrap';

.card {
    .card-header {
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
}

form.editor {
    display: contents;
}

footer {
    padding: 50px 0 20px;
}


.select2 {
    min-width: 100%;
    .select2-selection {
        border-color: rgba(50, 151, 211, 0.25);
        font-size: 0.875rem;
        line-height: 1.5;
        height: auto;
        padding: 0.625rem 0.75rem;
        transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        color: #8898aa;
        border: 1px solid #cad1d7;
        border-radius: 0.375rem;
        background-color: #fff;
        .select2-selection__rendered {
            padding-left: 0;
        }
        .select2-selection__arrow {
            height: calc(2.75rem + 2px);
        }
    }
}

.filterHeader{
    position: relative;
    z-index: 99;
    margin-top: -5px;
}
.card-header{
    height: 65px;
}

.dataTable {
    margin-top: -50px;
    .action {
        .button {
            margin-top: -40px;
            float: left;
        }
    }
    .group {
        display: inline-block;
    }
    .dataTables_empty {
        padding: 100px !important;
    }
    .dataTables_filter {
        font-size: 14px;
        color: #696d88;
        margin-right: 20px;
        input {
            height: 40px;
            width: 300px !important;
        }
    }
    .dataTables_length,
    .dataTables_info {
        display: none;
    }
    .dataTables_paginate {
        margin-top: 0 !important;
        padding: 15px;
        .page-link {
            width: auto;
            border-radius: 5px !important;
            padding: 0 10px;
        }
        .page-item.active .page-link {
            background-color: #5e72e4;
            border-color: #5e72e4;
        }
    }
    table {
        color: #696d88;
        margin-top: 20px !important;
        width: 100% !important;
        td,
        th {
            padding: 10px 20px;
        }
        tbody {
            border: 1px solid rgba(132, 146, 175, 0.3);
            background: white;
            tr {
                td {
                    font-size: 13px;
                    vertical-align: middle;
                    input {
                        display: inline-block;
                        width: auto;
                        line-height: 30px;
                        height: 30px;
                    }
                }
            }
            .button,
            button {
                padding: 5px 10px;
                background: rgba(132, 146, 175, 0.3);
                box-shadow: none;
                font-size: 14px;
                min-width: auto;
                color: #696d88;
                float: left;
                border: none;
                margin-left: 10px;
                &:hover {
                    cursor: pointer;
                }
            }
            form {
                float: left;
            }
        }
    }
}

img {
    max-width: 100%;
}

ul {
    margin: 0;
    padding: 0;
    li {
        list-style: none;
    }
}

.navbar-light .navbar-nav li .nav-link.active {
    color: rgba(0, 0, 0, 0.5);
    background: #f7f7f7;
}

.actions {
    margin-top: 20px;
    .btn {
        width: 100%;
    }
}

.selectize-control {
    .selectize-input {
        padding: 10px 8px 3px;
        min-height: 46px;
        border-color: rgba(50, 151, 211, 0.25);
        border-radius: 0.375rem;
        box-shadow: none;
    }
}

.dataTable .selectize-input {
    max-width: 100% !important;
    white-space: break-spaces;
}

.tracker{
    .input-group {
        border: 1px solid #ddd;
        .input-group-text {
            font-size: 0.8rem;
            background: #f5f5f5;
            border: none;
            min-width: 80px;
        }
        input {
            padding-left: 5px!important;
        }
    }
}

//DIFF POST
.diff-wrapper.diff th {
    font-weight: 700;
    cursor: default;
    -webkit-user-select: none;
    user-select: none;
}
.diff-wrapper.diff td:empty:after,
.diff-wrapper.diff th:empty:after {
    content: " ";
    visibility: hidden;
}
.diff-wrapper.diff td a,
.diff-wrapper.diff th a {
    color: #000;
    cursor: inherit;
    pointer-events: none;
}
.diff-wrapper.diff thead th {
    background: #a6a6a6;
    border-bottom: 1px solid black;
    padding: 4px;
    text-align: left;
}
.diff-wrapper.diff tbody.skipped {
    border-top: 1px solid black;
}
.diff-wrapper.diff tbody.skipped td,
.diff-wrapper.diff tbody.skipped th {
    display: none;
}
.diff-wrapper.diff tbody th {
    background: #cccccc;
    border-right: 1px solid black;
    text-align: right;
    vertical-align: top;
    width: 4em;
}
.diff-wrapper.diff tbody th.sign {
    background: #fff;
    border-right: none;
    padding: 1px 0;
    text-align: center;
    width: 1em;
}
.diff-wrapper.diff tbody th.sign.del {
    background: #fbe1e1;
}
.diff-wrapper.diff tbody th.sign.ins {
    background: #e1fbe1;
}
.diff-wrapper.diff.diff-html {
    white-space: pre-wrap;
    tab-size: var(--tab-size);
}
.diff-wrapper.diff.diff-html .ch {
    line-height: 1em;
    background-clip: border-box;
    background-repeat: repeat-x;
    background-position: left center;
}
.diff-wrapper.diff.diff-html .ch.sp {
    background-image: url('data:image/svg+xml,%3Csvg preserveAspectRatio="xMinYMid meet" viewBox="0 0 12 24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M4.5 11C4.5 10.1716 5.17157 9.5 6 9.5C6.82843 9.5 7.5 10.1716 7.5 11C7.5 11.8284 6.82843 12.5 6 12.5C5.17157 12.5 4.5 11.8284 4.5 11Z" fill="rgba%2860, 60, 60, 50%25%29"/%3E%3C/svg%3E');
    background-size: 1ch 1.25em;
}
.diff-wrapper.diff.diff-html .ch.tab {
    background-image: url('data:image/svg+xml,%3Csvg preserveAspectRatio="xMinYMid meet" viewBox="0 0 12 24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M9.5 10.44L6.62 8.12L7.32 7.26L12.04 11V11.44L7.28 14.9L6.62 13.9L9.48 11.78H0V10.44H9.5Z" fill="rgba%2860, 60, 60, 50%25%29"/%3E%3C/svg%3E');
    background-size: calc(var(--tab-size) * 1ch) 1.25em;
    background-position: 2px center;
}
.diff-wrapper.diff.diff-html .change.change-eq .old,
.diff-wrapper.diff.diff-html .change.change-eq .new {
    background: #fff;
}
.diff-wrapper.diff.diff-html .change .old {
    background: #fbe1e1;
}
.diff-wrapper.diff.diff-html .change .new {
    background: #e1fbe1;
}
.diff-wrapper.diff.diff-html .change .old.none,
.diff-wrapper.diff.diff-html .change .new.none,
.diff-wrapper.diff.diff-html .change .rep.none {
    background: transparent;
    cursor: not-allowed;
}
.diff-wrapper.diff.diff-html .change ins,
.diff-wrapper.diff.diff-html .change del {
    font-weight: bold;
    text-decoration: none;
}
.diff-wrapper.diff.diff-html .change ins {
    background: #94f094;
}
.diff-wrapper.diff.diff-html .change del {
    background: #f09494;
}
